import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CustomerClientService } from '@service/client/customer-client.service';
import { ShopModeService } from '@service/helpers/shop-mode.service';
import { WindowRefService } from '@service/window-service/window-ref.service';
import { ConsentType } from 'app/model/interfaces';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { BlockService } from '../../block/shared/block.service';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsletterComponent implements OnInit, OnDestroy
{
  @Output() private readonly openPopupEmitter: EventEmitter<any> = new EventEmitter();
  private destroy$: Subject<void> = new Subject();
  public isSigned: boolean = false;
  public phoneNumbers: string[] = [];

  constructor(private router: Router,
    private changeDet: ChangeDetectorRef,
    private windowRef: WindowRefService,
    private translate: TranslateService,
    private customerService: CustomerClientService,
    private shopModeService: ShopModeService,
    private blockService: BlockService)
  {
  }

  public ngOnDestroy(): void
  {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public ngOnInit(): void
  {
    this.customerService.customer$.pipe(filter(v => !!v), takeUntil(this.destroy$)).subscribe(res =>
    {
      this.isSigned = res.consents.filter(cons => cons.code == ConsentType.Newsletter).length > 0;
      this.changeDet.detectChanges();
    });

    const contact = this.blockService.getDataSync('contact').contact;

    if (contact.ecommerce.primaryPhoneNumber)
    {
      this.phoneNumbers.push(contact.ecommerce.primaryPhoneNumber);
    }

    if (contact.ecommerce.secondaryPhoneNumber)
    {
      this.phoneNumbers.push(contact.ecommerce.secondaryPhoneNumber);
    }
  }

  public openPopup($event): void
  {
    this.openPopupEmitter.emit($event);
  }

  public isInNewsletter(): string
  {
    if (this.isSigned)
    {
      return this.translate.instant('newsletter.signInAlready');
    } else
    {
      return this.translate.instant('newsletter.signIn');
    }
  }
}
