<div class="newsletter-wrapper">
  <div class="newsletter-description small-text">{{ 'newsletter.title' |translate }}</div>
  <div class="heading5 promotion">{{ 'newsletter.5%discount' |translate }}</div>
  <div class="body-text">{{ 'newsletter.subscribeNews' |translate }}</div>
  <button class="button primary-button" (click)="openPopup($event)"
          [ngClass]="{'disabled-same-colour':isSigned}">{{isInNewsletter()}}</button>
  <app-media-links></app-media-links>

  <div class="small-text phones" *ngIf="phoneNumbers?.length">
    {{ "helpline" | translate }}

    <ng-container *ngFor="let phoneNumber of phoneNumbers; let i = index">
      <a [registerClickEvent] [href]="'tel:' + phoneNumber | trim">
        {{ phoneNumber }}
      </a>
      <span *ngIf="phoneNumbers.length > 1 && i === 0"> / </span>
    </ng-container>

  </div>
</div>

