<section  class="mobile-menu"  [ngClass]="{ open: menuIsOpen }"  sdClickOutside  (clickOutside)="hideOverlay($event)">
  <div class="when-open">
    <div class="phone" [ngClass]="{ justifyCenter: state.length || searchListVisible }">

      <!-- <span class="small-text" *ngIf="!checkIfCategoryIsActive() && !searchListVisible">
        {{ "helpline" | translate }}
        <a [registerClickEvent] href="tel:801090905" (click)="phoneTo($event, '801090905')">801 09 09 05</a> /
        <a [registerClickEvent] href="tel:221002727" (click)="phoneTo($event, '221002727')">22 100 27 27</a>
      </span> -->

      <div class="small-text" *ngIf="!checkIfCategoryIsActive() && !searchListVisible && phoneNumbers?.length">
        {{ "helpline" | translate }}fdsfdf

        <ng-container *ngFor="let phoneNumber of phoneNumbers; let i = index">
          <a [registerClickEvent] [href]="'tel:' + phoneNumber | trim">
            {{ phoneNumber }}
          </a>
          <span *ngIf="phoneNumbers.length > 1 && i === 0"> / </span>
        </ng-container>

      </div>

      <span class="arrow-back ai ai-back" *ngIf="checkIfCategoryIsActive() || searchListVisible" (click)="backState()"></span>

      <span class="heading5" *ngIf="checkIfCategoryIsActive() || searchListVisible">
        {{ changeLinkNameIfKonto(activeCategory, searchListVisible) }}
      </span>

      <div class="img-wrapper cancel" (click)="closeMenu()">
        <i class="ai ai-cancel"></i>
      </div>

    </div>

    <div class="separator-gray"></div>
    <div class="separator1"></div>

    <div class="search-wrapper" *ngIf="!checkIfActiveCategoryIsKontoField && !checkIfCategoryIsActive() && !isMoreThanDesktopResolution()">
      <app-search #searchComponent [openedFromMenu]="true" (openedEmitter)="setSearchListAsVisible($event)" [childId]="'menu'" (searchingInProgress)="closeMenu(null, null, true)"></app-search>
    </div>

    <button *ngIf="checkIfActiveCategoryIsKontoField && !isUserLogged()" class="button secondary-button font-style-semi" (click)="goToPage('/' + urlTranslateService.urlMapping.customer.login)">
      {{ "login.logIn" | translate }}
    </button>

    <div *ngIf="checkIfActiveCategoryIsKontoField && !isUserLogged()" class="register-link body-text">
      <span class="font-style-light">
        {{ "login.noAccount" | translate }}
      </span>
      <span>
        <a class="color-success" (click)="goToPage('/' + urlTranslateService.urlMapping.customer.register)">
          {{ "login.register" | translate }}
        </a>
      </span>
    </div>

    <div class="category-wrapper" *ngIf="!checkIfActiveCategoryIsKontoField && !searchListVisible">
      <div class="list-wrapper list-filter" *ngFor="let elem of activeMenuLinks" >
        <span class="heading5" [ngClass]="{ 'font-style-light': !elem.filters && activeCategory, 'font-style-semi': elem.values }" >
          <img class="filter-image" *ngIf="elem.imageUrl" [src]="elem.imageUrl"/>
          <a [ngClass]="{ 'main-color': elem.promotion }" (click)="categoriesMenuItemClicked(elem)" >
            {{ elem.name }} {{ elem.value }}
          </a>
          <i class="arrow-image ai ai-next" *ngIf="(elem.attributes && elem.attributes.length) || elem.values" (click)="expandCategoriesMenuItemClicked( elem.name, elem.values ? elem.values : elem.attributes)"></i>
        </span>
      </div>
      <div class="list-wrapper-mobile-separator" *ngIf=" (!activeCategory || checkIfActiveCategoryIsKontoField) && !hideCategories && !searchIsActive">
        <div class="separator-gray"></div>
      </div>
    </div>

  </div>

  <div class="separator-gray margin-side" *ngIf="checkIfActiveCategoryIsKontoField && !isUserLogged()"></div>

  <div class="list-wrapper list-menu dedicated-margin" *ngIf=" (!activeCategory || checkIfActiveCategoryIsKontoField) && !hideCategories && !searchListVisible " [ngClass]="{ bolder: isUserLogged() }">

    <div class="single-wrapper" *ngFor="let elem of activeNavigationLinks">
      <div *ngIf="elem.icon_class" class="image-wrapper" [ngClass]="{ 'main-color': elem.promotion }" >
        <i [class]="elem.icon_class"></i>
      </div>
      <div class="heading5 link" [ngClass]="{ 'font-style-light body-text': checkIfActiveCategoryIsKontoField }" (click)="chooseNavigation(elem.name, elem.attributes)">
        <a [ngClass]="{ 'main-color': elem.promotion }">
          {{ changeLinkNameIfKonto(elem.name) }}
        </a>
        <i class="arrow-image ai ai-next" *ngIf="elem.attributes && elem.attributes.length"></i>
      </div>
    </div>

    <ng-container *ngIf="isUserLogged() && checkIfActiveCategoryIsKontoField">
      <button class="button secondary-button font-style-semi logout-button" (click)="logout()">
        {{ "login.logOut" | translate }}
      </button>
    </ng-container>

  </div>
</section>