import
{
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Resolutions } from "@resolutions";
import { CustomerClientService } from "@service/client/customer-client.service";
import { UrlHelperService } from "@service/helpers/url-helper.service";
import { OverlayService } from "@service/overlay/overlay.service";
import { SearchRepositoryService } from "@service/search-repository/search-repository.service";
import { WindowRefService } from "@service/window-service/window-ref.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { getImageUrlStatic } from "../../../../utils/utilsFunctions";
import { SearchComponent } from "../search/search.component";
import { PopupService } from "../../../service/popup/popup.service";
import { UrlTranslateService } from '@service/helpers/url-translate.service';
import { BlockService } from "@shared/block/shared/block.service";
import { Menu, MenuCategory } from "app/model/interfaces";
import { ShopModeService } from "@service/helpers/shop-mode.service";

@Component({
  selector: "app-mobile-menu",
  templateUrl: "./mobile-menu.component.html",
  styleUrls: ["./mobile-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileMenuComponent implements OnInit, OnDestroy
{
  private destroy$: Subject<void> = new Subject();
  private name: string = "mobile-menu";

  public getImageUrlStatic = getImageUrlStatic;

  public searchIsActive: boolean = false;
  public searchListVisible: boolean = false;
  public menuIsOpen: boolean = false;
  public hideCategories: boolean = false;
  public activeCategory: string;

  public menuLinks: MenuCategory[] = [];
  public activeMenuLinks: MenuCategory[] = [];
  public activeNavigationLinks: any[] = [];
  public phoneNumbers: string[] = [];

  public state: any[] = [];


  public readonly navigationLinks = [
    {
      promotion: true,
      icon_class: "ai ai-pin",
      name: this.translate.instant("navbar.findShop"),
      link: `/${this.urlTranslateService.routingTable.additional.pos}`,
    },
    {
      promotion: false,
      icon_class: "ai ai-phone",
      name: this.translate.instant("navbar.contact"),
      link: `/${this.urlTranslateService.routingTable.additional.contact}`,
    },
    {
      promotion: false,
      icon_class: "ai ai-account",
      name: this.translate.instant("account.title"),
      attributes: [
        {
          name: this.translate.instant("account.myOrders"),
          link: `/${this.urlTranslateService.urlMapping.customer.account.orders}`,
        },
        {
          name: this.translate.instant("account.myData"),
          link: `/${this.urlTranslateService.urlMapping.customer.account.myData}`,
        },
        {
          name: this.translate.instant("account.coupons"),
          link: `/${this.urlTranslateService.urlMapping.customer.account.myCoupons}`,
        },
        {
          name: this.translate.instant("account.opinions"),
          link: `/${this.urlTranslateService.urlMapping.customer.account.reviews}`,
        },
        {
          name: this.translate.instant("favourite.title"),
          link: `/${this.urlTranslateService.urlMapping.customer.account.favourite}`,
        },
        {
          name: this.translate.instant("main.lastWatched"),
          link: `/${this.urlTranslateService.urlMapping.customer.account.lastWatched}`,
        },
      ],
      link: `/${this.urlTranslateService.urlMapping.customer.account.orders}`,
    },
    {
      promotion: false,
      icon_class: "ai ai-heart",
      name: this.translate.instant("favourite.title"),
      link: `/${this.urlTranslateService.urlMapping.customer.account.favourite}`,
    },
  ];

  @Output() public readonly isHoveredOutput: EventEmitter<boolean> = new EventEmitter();
  @Output() public readonly mobileMenuClosed: EventEmitter<void> = new EventEmitter();
  @ViewChild("searchComponent") public searchComponent: SearchComponent;

  //#region Semi getters
  public isMoreThanDesktopResolution(): boolean
  {
    return (this.windowRef.getNativeWindowInnerWidth() >= Resolutions.resolutionDesktop);
  }
  public checkIfActiveCategoryIsKontoField: boolean
  public checkIfActiveCategoryIsKonto(): void
  {
    this.checkIfActiveCategoryIsKontoField = this.activeCategory === this.translate.instant("account.title");
  }
  public isUserLogged(): boolean
  {
    return this.cs.isUserLogged;
  }
  public checkIfCategoryIsActive(): boolean
  {
    return this.state.length > 0;
  }
  //#endregion

  //#region Init/Destroy
  constructor(
    private searchRepositoryService: SearchRepositoryService,
    private router: Router,
    private cs: CustomerClientService,
    private translate: TranslateService,
    private renderer2: Renderer2,
    private changeDet: ChangeDetectorRef,
    private overlayService: OverlayService,
    private windowRef: WindowRefService,
    private urlHelper: UrlHelperService,
    private popupService: PopupService,
    public urlTranslateService: UrlTranslateService,
    private blockService: BlockService,
    private shopModeService: ShopModeService,
  )
  {
  }

  public ngOnInit(): void
  {
    this.popupService.reserveName(this.name);

    if (this.shopModeService.isRuggo)
    {
      this.navigationLinks.shift();
    }

    this.activeNavigationLinks = this.navigationLinks.slice();

    const categories: Menu = this.blockService.getDataSync("menu");

    categories.categories.forEach((category: MenuCategory) =>
    {
      category.urlParams = this.urlHelper.parseQueryStringToObject(category.link);

      if (category.urlParams.hasOwnProperty("kategoria"))
      {
        category.code = category.urlParams["kategoria"];
      }
    });

    this.menuLinks = categories.categories;
    this.activeMenuLinks = categories.categories.slice();

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) =>
    {
      if (event instanceof NavigationEnd && this.windowRef.isWindowLoaded())
      {
        this.isHoveredOutput.emit(false);
        this.resetMenuAttributes();
        this.activeCategory = null;
        this.checkIfActiveCategoryIsKonto();
      }
    });

    const contact = this.blockService.getDataSync('contact').contact;

    if (contact.ecommerce.primaryPhoneNumber)
    {
      this.phoneNumbers.push(contact.ecommerce.primaryPhoneNumber);
    }

    if (contact.ecommerce.secondaryPhoneNumber)
    {
      this.phoneNumbers.push(contact.ecommerce.secondaryPhoneNumber);
    }
  }

  public ngOnDestroy(): void
  {
    this.popupService.clearName(this.name);
    this.overlayService.hide();
    this.menuIsOpen = false;
    this.state = [];
    this.activeCategory = null;
    this.checkIfActiveCategoryIsKonto();
    this.windowRef.unBlockScroll();
    this.destroy$.next();
    this.destroy$.complete();
  }
  //#endregion

  //#region Open menu
  public openMenu(disableReload?: boolean, focusOnSearchInput?: boolean): void
  {
    this.renderer2.addClass(document.body, "mobile-nav-open");
    this.menuIsOpen = true;
    this.windowRef.blockScroll();

    if (this.windowRef.getNativeWindow().location.href.includes(`/${this.urlTranslateService.urlMapping.customer.account.base}`) && !focusOnSearchInput)
    {
      this.chooseNavigation(this.translate.instant("account.title"), this.navigationLinks[2].attributes);
    }

    this.overlayService.show();
    this.popupService.openPopup(this.name, !!disableReload);
    this.changeDet.detectChanges();
  }

  public openMenuAndFocusOnSearchInput(): void
  {
    this.openMenu(false, true);
    // quickfix for clickoutside directive
    setTimeout(() => this.searchComponent.open(), 100);
    this.searchRepositoryService.setSearchVisible(true);
    this.searchIsActive = true;
  }
  //#endregion

  //#region Close menu
  public closeMenu($event?: any, goWithRouter?: boolean, disableReload?: boolean): void
  {
    if ($event)
    {
      if (!$event.hasOwnProperty("navigateByUrl") && !goWithRouter)
      {
        return;
      }

      if (!$event.hasOwnProperty("navigateByUrl") && goWithRouter)
      {
        this.router.navigate([$event], { replaceUrl: true });
        this.renderer2.removeClass(document.body, "mobile-nav-open");
      }
    }

    this.renderer2.removeClass(document.body, "mobile-nav-open");
    this.menuIsOpen = false;
    this.isHoveredOutput.emit(false);
    this.resetMenuAttributes();
    this.changeDet.detectChanges();
    // We need to unblock scroll after popup close
    setTimeout(() =>
    {
      this.windowRef.unBlockScroll(false);
      this.overlayService.hide();
    }, 100);

    if (this.windowRef.getNativeWindowInnerWidth() < Resolutions.resolutionDesktop)
    {
      this.mobileMenuClosed.emit();
    }

    if ($event)
    {
      if ($event.hasOwnProperty("navigateByUrl"))
      {
        this.popupService.closePopup(this.name, true, true);
        this.router.navigateByUrl($event.navigateByUrl);
      }
    }
    else if (this.popupService.isActivePopup(this.name))
    {
      this.popupService.closePopup(this.name, !!disableReload);
    }
  }

  public logout(): void
  {
    this.closeMenu(null, null, true);
    this.cs.logout();
  }


  public hideOverlay($event): void
  {
    if (typeof $event.target?.className === "string" && $event.target?.className?.includes("overlay"))
    {
      this.closeMenu();
    }
  }

  private resetMenuAttributes(): void
  {
    this.activeCategory = null;
    this.checkIfActiveCategoryIsKonto();
    this.searchRepositoryService.setSearchVisible(false);
    this.searchIsActive = false;
    this.activeMenuLinks = this.menuLinks.slice();
    this.activeNavigationLinks = this.navigationLinks.slice();
    this.state = [];
  }

  @HostListener("window:popstate", ["$event"])
  private backButtonHandler(): void
  {
    if (!this.popupService.isActiveParam("m", this.name) && this.popupService.isActivePopup(this.name)
    )
    {
      this.closeMenu(null, null, true);
    }
  }
  //#endregion

  //#region Menu navigation
  public backState(): void
  {
    this.state.pop();

    if (this.state.length > 0)
    {
      this.activeCategory = this.state[this.state.length - 1].name;
      this.checkIfActiveCategoryIsKonto();
      this.activeMenuLinks = this.state[this.state.length - 1].attributes;
    }
    else
    {
      if (this.checkIfActiveCategoryIsKontoField)
      {
        this.activeNavigationLinks = this.navigationLinks.slice();
      }
      else
      {
        this.activeMenuLinks = this.menuLinks.slice();
      }

      this.activeCategory = null;
      this.checkIfActiveCategoryIsKonto();
    }

    this.changeDet.detectChanges();
  }

  public chooseNavigation(name: string, attributes: any[]): void
  {
    if (!attributes)
    {
      this.closeMenu({ navigateByUrl: this.activeNavigationLinks.filter((el) => el.name == name)[0].link });
      return;
    }

    this.activeNavigationLinks = attributes;
    this.activeCategory = name;
    this.checkIfActiveCategoryIsKonto();
    this.state.push({ name: name, attributes: attributes });
  }

  public expandCategoriesMenuItemClicked(name: string, attributes: any[]): void
  {
    this.activeCategory = name;
    this.checkIfActiveCategoryIsKonto();
    this.activeMenuLinks = attributes;
    this.state.push({ name: name, attributes: attributes });
  }

  public categoriesMenuItemClicked(elem: any): void
  {
    if (elem.hasOwnProperty("subCategories"))
    {
      // Main Category
      let url = this.getLink(elem.link, null);
      let params = this.getLinkQuery(elem.link, null);
      if (params)
      {
        url = url + "?" + this.urlHelper.parseQueryObjectToString(params);
      }
      this.closeMenu({ navigateByUrl: url, });
    }
    else if (this.state[1])
    {
      // Value of subcategory
      let url = this.getLink(this.state[0].attributes.filter((at) => at.name == this.state[1].name)[0].linkTemplate, elem.code);
      let params = this.getLinkQuery(this.state[0].attributes.filter((at) => at.name == this.state[1].name)[0].linkTemplate, elem.code);
      if (params)
      {
        url = url + "?" + this.urlHelper.parseQueryObjectToString(params);
      }
      this.closeMenu({ navigateByUrl: url, });
    }
    else
    {
      // Enter subcategory
      this.expandCategoriesMenuItemClicked(elem.name, elem.values);
    }
  }

  public getLinkQuery(link: string, code: string): any
  {
    link = link.replace("{CODE}", code);

    if (!link.includes("="))
    {
      return '';
    }
    else
    {
      return this.urlHelper.parseQueryStringToObject(link);
    }
  }

  public getLink(link: string, code: string): string
  {
    link = link.replace("{CODE}", code);

    if (!link.includes("="))
    {
      return '/' + link;
    }
    else
    {
      return '/' + this.urlTranslateService.routingTable.shop.listing;
    }
  }

  public phoneTo($event, phoneNumber)
  {
    $event.preventDefault();
    (window as any).location.href = `tel:${phoneNumber}`
  }
  //#endregion

  public setSearchListAsVisible($event: boolean): void
  {
    this.searchListVisible = $event;
    this.searchRepositoryService.setSearchVisible($event);
  }

  public changeLinkNameIfKonto(name: string, searchActive: boolean): string
  {
    if (searchActive)
    {
      return this.translate.instant("navbar.search");
    }

    if (name === this.translate.instant("account.title") && this.isUserLogged())
    {
      return this.translate.instant("account.yourAccount");
    }

    return name;
  }

  //#region Gui updates (resize etc)
  @HostListener("window:resize", ["$event"])
  private onEventResize($event): void
  {
    if (this.windowRef.getNativeWindowInnerWidth() >= Resolutions.resolutionDesktop)
    {
      if (this.menuIsOpen == true)
      {
        this.closeMenu();
      }
    }
  }
  //#endregion

  //#region Exit
  public goToPage(link: string): void
  {
    this.closeMenu(link, true);
  }
  //#endregion
}
