import { Location, DOCUMENT } from "@angular/common";
import
{
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { NavigationStart, NavigationEnd, Router } from "@angular/router";
import { CustomerClientService } from "@service/client/customer-client.service";
import { AnalyticsService } from "@service/analytics/abstract/analytics.service";
import { ShopModeService } from "@service/helpers/shop-mode.service";
import { HttpService } from "@service/http/http.service";
import { NavbarService, NotificationBar } from "@service/navbar/navbar.service";
import { OverlayService } from "@service/overlay/overlay.service";
import { SeoService } from "@service/seo/seo.service";
import { TimestampService } from "@service/timestamp/timestamp.service";
import { WindowRefService } from "@service/window-service/window-ref.service";
import { ScrollPositionService } from "@service/scrollPosition.service"
import { Observable, Subject, Subscription } from "rxjs";
import { map, take, takeUntil } from "rxjs/operators";
import { StorageService } from "@service/storage/storage.service";
import { CheckoutService } from "@service/checkout/checkout.service";
import { ErrorsService } from "@service/errors/errors.service";
import { UrlTranslateService } from "@service/helpers/url-translate.service";
import { Resolutions } from "@resolutions";
import { ConfigService } from "@service/config/config.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy
{
  private destroy$: Subject<void> = new Subject();
  public loadingApp: boolean = true;
  public showErrorOverlay: boolean = false;
  public loadingAppSub: Subscription;
  public isCheckoutModule: boolean = false;
  public isMobile: boolean = false;
  private interactionTimeout: any;
  private lastLogoutResetTime: number;
  private logoutTime: number = 1000 * 60 * 15;
  public isFinalStepOfCheckout: boolean = false;

  @ViewChild("navbar", { read: ElementRef }) private navbar: ElementRef;
  @ViewChild("mobileMenu") mobileMenu: any;
  public notification$: Observable<NotificationBar>;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private windowRef: WindowRefService,
    private configSercice: ConfigService,
    private timeStampService: TimestampService,
    private customerService: CustomerClientService,
    private httpService: HttpService,
    public shopModeService: ShopModeService,
    private location: Location,
    private router: Router,
    private analyticsService: AnalyticsService,
    private navbarService: NavbarService,
    private overlayService: OverlayService,
    private changeDet: ChangeDetectorRef,
    private seoService: SeoService,
    private renderer: Renderer2,
    private scrollPositionService: ScrollPositionService,
    private storageService: StorageService,
    private checkoutService: CheckoutService,
    public errorsService: ErrorsService,
    public urlTranslateService: UrlTranslateService,
    private ngZone: NgZone
  )
  {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) =>
    {
      if (event instanceof NavigationStart)
      {
        if (window.location.pathname !== event.url)
        {
          this.seoService.clear();
        }

        if (event.navigationTrigger === "imperative")
        {
          this.scrollPositionService.addPosition(this.location.path());
        }
        else if (event.navigationTrigger === "popstate")
        {
          this.scrollPositionService.popPositionAndWait();
        }
      }

      if (event instanceof NavigationEnd)
      {
        this.isFinalStepOfCheckout = this.checkoutService.currentStep.includes(`/${this.urlTranslateService.urlMapping.checkout.final}`) || this.router.url.includes(`/${this.urlTranslateService.urlMapping.checkout.final}`);

        this.scrollPositionService.resetPosition(this.router.url);

        if (window.location.pathname !== event.url)
        {
          this.seoService.setBasics();
          this.seoService.setStandardDescription();
          this.seoService.setStandardImage();
        }

        this.analyticsService.registerPageView(event);
        this.seoService.setNofollowByUrl(window.location.pathname + window.location.search);

        this.isCheckoutModule = this.router.url.indexOf(`/${this.urlTranslateService.routingTable.checkout.base}`) >= 0;
        this.onScroll();
        this.changeDet.detectChanges();
      }
    });

    this.ngZone.onStable.pipe(takeUntil(this.destroy$), take(1)).subscribe(() =>
    {
      analyticsService.initialize();
    });

    this.windowRef.loadingAppSubject.next(false);

    //#region Redownload customer on tab change
    document.addEventListener("visibilitychange",
      () =>
      {
        if (!this.router.url.includes(this.urlTranslateService.routingTable.checkout.final))
        {
          this.timeStampService.update();
          this.timeStampService.updateTimeStampOnRequest();
        }
      },
      false
    );
    //#endregion

    this.analyticsService.registerApplicationStart();
  }

  public ngOnInit(): void
  {
    this.isMobileCheck();

    // if (window.location.host.includes("ruggo") || this.configSercice.MainDomain.includes("ruggo"))
    // {
    //   this.shopModeService.setRuggoMode();
    // }
    // else
    // {
    //   if (window.location.host.split(".").length >= 3 && window.location.host.split(".")[0].length === 3)
    //   {
    //     this.shopModeService.setKioskMode();
    //   }
    // }

    this.httpService.showTechnicalErrorOverlay$.subscribe((show) =>
    {
      this.showErrorOverlay = show;

      if (show)
      {
        document.getElementsByTagName("html")[0].style.overflow = "hidden";
        setTimeout(() => { window.location.reload(); }, 5000);
      }
    });

    this.loadingAppSub = this.windowRef.loadingAppSubject.subscribe((res) =>
    {
      this.loadingApp = res;
    });

    this.notification$ = this.navbarService.notification$.pipe(
      map((data) =>
      {
        setTimeout(() =>
        {
          this.changeDet.detectChanges();
        }, 144);
        return data;
      })
    );

    if (this.shopModeService.isKiosk)
    {
      this.setLogoutTimeout();
    }
  }

  public ngOnDestroy(): void
  {
    this.destroy$.next();
    this.destroy$.complete();
    this.loadingAppSub.unsubscribe();
  }

  private setLogoutTimeout(): void
  {
    if (this.interactionTimeout)
    {
      clearTimeout(this.interactionTimeout);
    }

    this.lastLogoutResetTime = new Date().getTime();
    this.interactionTimeout = setTimeout(() =>
    {
      this.storageService.reset();
      this.customerService.logout();
    }, this.logoutTime);
  }

  // public isFinalStepOfCheckout(): boolean
  // {
  //   return this.checkoutService.currentStep.includes(`/${this.urlTranslateService.urlMapping.checkout.final}`) ||
  //     this.router.url.includes(`/${this.urlTranslateService.urlMapping.checkout.final}`);
  // }

  // tslint:disable-next-line:typedef
  public mobileMenuClosed()
  {
    this.renderer.addClass(this.navbar.nativeElement, "fixed");
  }

  public openMobileMenu(focusOnSearchInput)
  {
    if (focusOnSearchInput)
    {
      this.mobileMenu.openMenuAndFocusOnSearchInput()
    } else
    {
      this.mobileMenu.openMenu()
    }
  }

  public isMobileCheck()
  {
    if (this.isMobile !== this.windowRef.getNativeWindowInnerWidth() < Resolutions.resolutionDesktop)
    {
      this.isMobile = this.windowRef.getNativeWindowInnerWidth() < Resolutions.resolutionDesktop;
      this.changeDet.detectChanges();
    }
  }

  public clearError()
  {
    this.navbarService.updateNotificationSubject(null);
  }

  //#region Windows events (scroll, resize, etc.)
  @HostListener("window:scroll", ["$event"])
  private onScroll(): void
  {
    if (this.interactionTimeout && Math.abs(new Date().getTime() - this.lastLogoutResetTime) > 5000)
    {
      this.setLogoutTimeout();
    }
  }

  @HostListener("window:resize", ["$event"])
  private onResize(): void
  {
    this.isMobileCheck();
    this.onScroll();
  }

  @HostListener("window:popstate", ["$event"])
  private backButtonHandler($event): void
  {
    $event.stopPropagation();
    $event.preventDefault();
    this.overlayService.hide();
  }
  //#endregion
}
