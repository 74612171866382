<div class="container">
  <footer [ngClass]="{ 'bottom-page-wrapper': true, 'checkout-footer': isCheckout && isFinalStepOfCheckout }" *ngIf="showFooterLinks || (isCheckout && isFinalStepOfCheckout)" >

    <app-newsletter (openPopupEmitter)="openNewsletterPopup($event)" ></app-newsletter>

    <div class="separator-gray border full-width-section"></div>

    <div class="footer-columns" *ngIf="columns && columns.length > 0">
      <div *ngFor="let column of columns" class="category-wrapper" [ngClass]="{'open': column.isExpanded}" (click)="column.isExpanded = !column.isExpanded" >

        <div class="header-wrapper">
          <div class="column-name">
            {{ column.name }}
          </div>

          <div class="arrow-wrapper ai" [ngClass]="{'ai-bottom': !column.isExpanded, 'ai-top': column.isExpanded}"></div>
        </div>

        <div class="single-link" *ngFor="let link of column.links">
          <ng-container *ngIf="link.url === '/zglos-blad' || link.url === '/raport_error'" >
            <a style="cursor: pointer" (click)="showIntercome($event)">
              {{ link.text }}
            </a>
          </ng-container>

          <ng-container *ngIf="link.url !== '/zglos-blad' && link.url !== '/raport_error'" >
            <a [routerLink]="link.url">{{ link.text }}</a>
          </ng-container>
        </div>

      </div>
    </div>

  </footer>

  <div class="bottom-caption-footer tiny-text font-style-book" *ngIf="!isCheckout || (isCheckout && isFinalStepOfCheckout)" >

    <div>
      {{ "footer.copyright" | translate }}
    </div>

    <div class="small-text phones" *ngIf="phoneNumbers?.length">
      {{ "helpline" | translate }}

      <ng-container *ngFor="let phoneNumber of phoneNumbers; let i = index">
        <a [registerClickEvent] [href]="'tel:' + phoneNumber | trim">
          {{ phoneNumber }}
        </a>
        <span *ngIf="phoneNumbers.length > 1 && i === 0"> / </span>
      </ng-container>

    </div>

  </div>

  <ng-container *ngIf="isCheckout && !isFinalStepOfCheckout">
    <div class="checkout-footer">
      <div class="bottom-caption-footer tiny-text font-style-book cart">

        <p class="show-tablet">
          {{ "navbar.needHelp" | translate }}
          <span>
            <ng-container *ngFor="let phoneNumber of phoneNumbers; let i = index">
              <a [registerClickEvent] [href]="'tel:' + phoneNumber | trim">
                 {{ phoneNumber }}
              </a>
              <ng-container *ngIf="phoneNumbers.length > 1 && i === 0"> / </ng-container>
            </ng-container>
          </span>
        </p>

        <p class="separator-custom hidden-tablet">
          <ng-container *ngFor="let phoneNumber of phoneNumbers; let i = index">
            <a [registerClickEvent] [href]="'tel:' + phoneNumber | trim">
                {{ phoneNumber }}
            </a>
            <ng-container *ngIf="phoneNumbers.length > 1 && i === 0"> / </ng-container>
          </ng-container>
        </p>

        <span class="mobile-footer">
          <p>
            <span class="desktop-icon-locker">
              <img [src]="'/assets/icons/lock.svg'" />
            </span>
            {{ "footer.yourDataIsSave" | translate }}
          </p>
          <p>
            {{ "footer.copyright" | translate }}
          </p>
        </span>

      </div>
    </div>
  </ng-container>
</div>

<app-newsletter-popup
  name="newsletter"
  [mainTitle]="('newsletter.5%discount' | translate).concat(' !')"
  [secondaryTitle]="'newsletter.subscribeNews' | translate"
  [defaultButtonText]="'newsletter.signIn' | translate"
  pictureAltName="newsletter"
  pictureSrc="/images/banners/newsletter-popup.jpg"
  pictureExtension="jpg"
  [buttonType]="'primary-button'"
  [withImage]="true"
  [isNewsletter]="true"
  [showEmail]="true">
</app-newsletter-popup>
